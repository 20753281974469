// ============================================================================================================================== //
// =================================================== Contrast Functions ======================================================= //
// ============================================================================================================================== //

var setUpColourContrast = function () {
    var usersCookie = getCookie('colour-theme');

    if (usersCookie) {
        $('body').attr('id', usersCookie);

        // set contrast slider to ticked
        $('.color_contrast').find('input[data-contrast-theme]').attr('checked', 'checked');
    }

    // hook up button
    $('.color_contrast').find('input[data-contrast-theme]').change(function (e) {
        theme = $(this).data('contrast-theme');

        console.log(theme);

        if (!$(this).is(':checked')) {
            // clear contrast theme
            theme = '';
        }

        changeContrastTheme(theme);

        e.preventDefault();
        return false;
    });
};

var changeContrastTheme = function (themeName) {
    $('body').removeAttr('id');

    if (typeof (themeName) !== 'undefined' && themeName != "") {

        //Remove old theme classes and add new one
        $('body').attr('id', themeName);
    }

    //Create theme cookie
    createCookie("colour-theme", themeName, 0, 0, 182);
};

// ============================================================================================================================== //
// =================================================== Font Size Functions ====================================================== //
// ============================================================================================================================== //

var setupFontResize = function () {
    var usersCookieFont = getCookie('font-size');

    if (usersCookieFont) {
        $('html').addClass(usersCookieFont);
    }

    // make sure everythings layed out correctly
    setTimeout(function () { $(document).foundation('equalizer', 'reflow'); }, 200);

    // hook up buttons
    $('.text_size').find('a[data-change-type]').click(function (e) {
        changeType = $(this).data('change-type');
        changeFontSize(changeType);

        e.preventDefault();
        return false;
    });
};

var changeFontSize = function (changeType) {
    var newSize,
    usersCookieFont = getCookie('font-size');

    if (changeType === 'up') {
        switch (usersCookieFont) {
            case "font_0":
                newSize = "font_1";
                break;
            case "font_1":
                newSize = "font_2";
                break;
            case "font_2":
                newSize = usersCookieFont;
                break;
            case "font_-1":
                newSize = "font_0";
                break;
            case "font_-2":
                newSize = "font_-1";
                break;
            default:
                newSize = "font_1";
        }
    }
    else {
        switch (usersCookieFont) {
            case "font_0":
                newSize = "font_-1";
                break;
            case "font_-1":
                newSize = "font_-2";
                break;
            case "font_-2":
                newSize = usersCookieFont;
                break;
            case "font_1":
                newSize = "font_0";
                break;
            case "font_2":
                newSize = "font_1";
                break;
            default:
                newSize = "font_-1";
        }
    }

    //Remove old font classes and add new one
    $('html').removeClass(function (index, css) {
        return (css.match(/\bfont_\S+/g) || []).join(' ');
    }).addClass(newSize);

    //Create font cookie
    createCookie("font-size", newSize, 0, 0, 182);

    setTimeout(function () { $(document).foundation('equalizer', 'reflow'); }, 200);
};

// ============================================================================================================================== //
// =================================================== Back to top Function ===================================================== //
// ============================================================================================================================== //

var scrollToTopOfPage = function () {
    $('body,html').animate({
        scrollTop: 0
    }, 300);
};

// ============================================================================================================================== //
// =============================================== Init Colour and font on load ================================================= //
// ============================================================================================================================== //

setUpColourContrast();
setupFontResize();
