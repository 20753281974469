function createCookie(name, value, mins, hours, days) {
    mins = typeof mins !== 'undefined' ? mins : 0;
    hours = typeof hours !== 'undefined' ? hours : 0;
    days = typeof days !== 'undefined' ? days : 0;

    mins = mins * 60 * 1000;
    hours = hours * 60 * 60 * 1000;
    days = days * 24 * 60 * 60 * 1000;
    expires = "";

    if (mins !== 0 || hours !== 0 || days !== 0) {
        //Not a session cookie
        var date = new Date();
        date.setTime(date.getTime() + mins + hours + days);
        expires = "; expires=" + date.toGMTString();
    }

    //Set the cookie
    document.cookie = name + "=" + value + expires + "; path=/";
}

//Look for a cookie based on name
function getCookie(name) {
    name = name + "=";
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var c = cookies[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }
}

function deleteCookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
}
