var initRTEImages = function () {
    /* CMS floated images */
    $("#main_content .rte img").each(function () {
        var img = $(this);
        if (img.css('float') === 'left') {
            img.addClass('img_float_left');
        }
        else if (img.css('float') === 'right') {
            img.addClass('img_float_right');
        }
    });
};