// Foundation helper media query functions

isSmall = function () {
    return matchMedia(Foundation.media_queries['small']).matches && !matchMedia(Foundation.media_queries.medium).matches;
};

isMedium = function () {
    return matchMedia(Foundation.media_queries['medium']).matches && !matchMedia(Foundation.media_queries.large).matches;
};

isLarge = function () {
    return matchMedia(Foundation.media_queries['large']).matches;
};

// *Usage*
// if (isLarge()) {
// 	alert("Test");
// }
